<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'Happy Tickets - sell tickets for all your events',
      meta: [
        { vmid: 'description', name: 'description', content: "Happy Tickets allows you to sell and manage tickets for all your events.  We charge just 25p per ticket sold, the best value event ticketing available." },
      ]
    }
  }
</script>
