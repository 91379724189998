import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from "./auth/authGuard";
import { authCheck } from "./auth/authCheck";

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/Dashboard'
    },
    {
      path: '/loggedOut',
      redirect: to => {
        var path = $cookies.get("returnToPath");
        return {path:path}
      }
    },
    {
      path: '/ticket/:boxOfficeId/:ticketId',
      component: () => import('@/views/door/Ticket'),
    },
    {
      path: '/BoxOffice',
      beforeEnter: authCheck,
      component: () => import('@/views/boxOffice/Index'),
      children: [
        {
          path: 'Location',
          component: () => import('@/views/boxOffice/Location'),
        },
        {
          path: ':boxOfficePath',
          component: () => import('@/views/boxOffice/BoxOffice'),
        },
        {
          path: ':boxOfficePath/:eventPath',
          component: () => import('@/views/boxOffice/Event'),
        },
        {
          path: ':boxOfficePath/:eventPath/:eventTimeId',
          component: () => import('@/views/boxOffice/Event'),
        },
        {
          path: ':boxOfficePath/:eventPath/:eventTimeId/calendar.ics',
          component: () => import('@/views/boxOffice/EventIcsCalendar'),
        },
        {
          path: ':boxOfficePath/:eventPath/:eventTimeId/calendar.google',
          component: () => import('@/views/boxOffice/EventGoogleCalendar'),
        },
      ]
    },
    {
      path: '/Dashboard',
      beforeEnter: authGuard,
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          path: '',
          component: () => import('@/views/dashboard/BoxOffices'),
        },
        {
          path: 'BoxOffices',
          component: () => import('@/views/dashboard/BoxOffices'),
        },
        {
          path: 'Events/:id',
          component: () => import('@/views/dashboard/Events'),
        },
        {
          path: 'BoxOfficeSettings/:id',
          component: () => import('@/views/dashboard/BoxOfficeSettings'),
        },
        
        // needed for callbacks
        {
          path: 'BoxOfficeSettings',
          component: () => import('@/views/dashboard/BoxOfficeSettings'),
        },
        {
          path: 'Images/:id',
          component: () => import('@/views/dashboard/Images'),
        },
        {
          path: 'Users/:id',
          component: () => import('@/views/dashboard/Users'),
        },
        {
          path: 'Wizard/:id',
          component: () => import('@/views/dashboard/Wizard'),
        },

        // needed for callbacks
        {
          path: 'Wizard',
          component: () => import('@/views/dashboard/Wizard'),
        },
        {
          path: 'BoxOfficeSales/:id',
          component: () => import('@/views/dashboard/BoxOfficeSales'),
        },
        {
          path: 'Profile',
          beforeEnter: authCheck,
          component: () => import('@/views/dashboard/Profile'),
        },
        {
          path: 'AllSearch/:id',
          component: () => import('@/views/dashboard/AllSearch'),
        },
        {
          path: 'EventDetails/:id',
          component: () => import('@/views/dashboard/EventDetails'),
        },
        {
          path: 'EventReports/:id',
          component: () => import('@/views/dashboard/EventReports'),
        },
        {
          path: 'TicketTypes/:id',
          component: () => import('@/views/dashboard/TicketTypes'),
        },
        {
          path: 'Products/:id',
          component: () => import('@/views/dashboard/Products'),
        },
        {
          path: 'EventTimes/:id',
          component: () => import('@/views/dashboard/EventTimes'),
        },
        {
          path: 'TicketType/:id',
          component: () => import('@/views/dashboard/TicketType'),
        },
        {
          path: 'EventTime/:id',
          component: () => import('@/views/dashboard/EventTime'),
        },
        {
          path: 'Product/:id',
          component: () => import('@/views/dashboard/Product'),
        },
        {
          path: 'Order/:id',
          component: () => import('@/views/dashboard/Order'),
        },
        {
          path: 'Ticket/:id',
          component: () => import('@/views/dashboard/Ticket'),
        },
        {
          path: 'OrderItem/:id',
          component: () => import('@/views/dashboard/OrderItem'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})
