import { getInstance } from "./index";

export const authCheck = (to, from, next) => {

  const authService = getInstance();

  const fn = () => {
    return next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
